.info-block {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: contents;
  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  .image-block {
    &.info-block-1 {
      img {
        height: 120px;
      }
    }

    &.server-error {
      margin-top: 50px;
      @media (min-width: 768px) {
        margin-top: 40px;
      }

      img {
        height: 412px;
        margin-bottom: -96px;
        margin-top: -91px;
        max-width: 100%;
      }
    }

    img {
      margin: 0 auto;
      display: block;
    }

    margin-bottom: 20px;
  }

  .text-block {
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 21px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px;
  }

  .title-block {
    color: #4A4A4A;
    font-family: "Google Sans", sans-serif;
    font-size: 27px;
    font-weight: bold;
    line-height: 45px;
    margin-bottom: 30px;
    text-align: center;
  }

  .button-block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .sils-btn {
      width: 300px;
      margin-bottom: 30px;

      @media (min-width: 647px) {
        &:nth-child(2) {
          margin-left: 30px;
        }
      }

    }

    .info-exception-1 {
      max-width: 370px;
      width: 100%;
    }
  }

  .desc-block {
    color: #9B9B9B;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
  }

  .search-block {
    form {
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;
      }

      max-width: 570px;
      display: block;
      margin: 0 auto;
    }
  }

  .error-block {
    color: #E94B5E;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .server-error-block {
    color: #E94B5E;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
  }

  a {
    font-size: 16px;
  }

}

.sils-btn-type-3 {
  text-decoration: none;
  cursor: pointer;
  &:hover{
    text-decoration: none;
  }
  &:focus{
    outline: 0;
  }
  border: 2px solid #6CB644;
  border-radius: 5px;
  height: 40px;
  display: flex;
  background-color: #6CB644;
  transition: 0.3s;
  width: 100%;
  -webkit-appearance: initial;

  span {
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    margin: auto;
  }

  &:hover {
    background-color: #609F3E;
    border-color: #609F3E;
    transition: 0.3s;
  }

  &:disabled {
    background-color: #F6F4F3;
    border-color: #F6F4F3;

    span {
      color: #D6D6D6;
    }
  }
}

.spacer-bottom-30 {
  margin-bottom: 30px !important;
}